// Color Variables
$primary-color: #41228e;
$secondary-color: #2bfdff;
$text-color: #4c4c4c;
$text-color-dark: #000;
$text-color-light: #999;
$body-color: #fff;
$border-color: #c7c7c7;
$black: #000;
$white: #fff;
$gray: #eaeaea;

// Font Variables
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Playfair Display', serif;
$icon-font: 'themify';